import styled from "@emotion/styled";

export const Footer = styled.div`
  background-color: #131f66;
  bottom: 0;
  color: #fff;
  min-height: 200px;
  position: absolute;
  width: 100%;

  & a {
    border: 0;
    color: #fff;
  }
`;
